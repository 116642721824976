import React from 'react';

const Navbar = () => {
    return (
        <nav className="navbar" style={{ backgroundColor: '#6633ff' }}>
            <div className="container-fluid">
                <a className="navbar-brand ps-5" href="#">
                    <img
                        src="https://www.yomecontrolo.cl/static/media/Logo.67a3d5d742cadc96148a.png"
                        alt="Logo"
                        width="231"
                        height="64"
                        className="d-inline-block align-text-top"
                    />
                </a>
            </div>
        </nav>
    );
};

export default Navbar;